body {
	font-size: 0.8rem;
}

html,body {
	font-family: lato;
}

header+.container {
	padding-top: 32px;
}

table.table-responsive.table.table-striped a {
	color: black;
	text-decoration: underline;
}

* {
	outline: none;
}

.btn-link {
	color: black;
	text-decoration: underline;
	font-size: 13px;
}

.jumbotron {
	padding: 2rem 1rem;
	padding-left: 0;
	background-color: white;
	border-radius: 0;
	text-align: center;
}

input.form-control {
	border-radius: 0;
}

button.btn-primary {
	border-radius: 0;
	background-color: black;
	border-color: black;
}

button.btn-primary.disabled, button.btn-primary:disabled {
	border-radius: 0;
	background-color: black;
	border-color: black;
	cursor: not-allowed;
}

span.badge.badge-secondary {
	background-color: #ED1B5D;
	border-radius: 0;
}

table.table-responsive.table.table-striped tbody tr td:first-child {
    font-weight: bold;
}

a.navbar-brand img {
	width: 200px;
}

h2 {
	font-size: 48px;
}

ul.navbar-nav.mr-auto a {
	text-decoration: underline;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light {
	background-color: white!important;
}

.container form+h1 {
	margin-top: 32px;
}

.form-group label span {
	font-weight: bold;
	font-size: 15px;
}

span.badge.badge-secondary+span.badge.badge-secondary {
	display: none;
}

.table .thead-dark th {
	background-color: black;
}

.form-check {
	margin: 15px 0;
}

small#fieldRequired {
	margin-bottom: 15px;
}

.container {
	width: 1300px;
	max-width: 99%;
}

.table-dark {
	background-color: black;
}

table.table-responsive.table.table-striped thead {
	border: 1px solid #80808075;
}

table.table-responsive.table.table-striped tbody {
	border: 1px solid #80808075;
}

.DateInput_input {
	color: black;
}

.return a {
    text-decoration: underline;
    color: black;
}

img.logoform {
    width: 200px;
    margin-bottom: 20px;
}

.h1, h1 {
    font-size: 55px;
    margin-bottom: 20px;
    font-weight: 900;
}

.table td, .table th {
    padding: 1.5rem 0.75rem;
}

.totalnum {
    line-height: 1.2;
    margin: 20px auto;
    flex-direction: column;
    font-weight: bold;
    float: none;
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 32px;
    background-image: url(/background-login.png);
    background-size: cover;
}

.jumbotron h1+h3 {
    line-height: 1.2;
    margin: 20px auto;
    flex-direction: column;
    font-weight: bold;
    float: none;
    width: 190px;
    height: 190px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 26px;
    background-image: url(/background-login.png);
    background-size: cover;
}

.jumbotron h1+h3+h3 {
    line-height: 1.2;
    margin: 20px 0 20px 10%;
    flex-direction: column;
    font-weight: bold;
    float: none;
    width: 190px;
    height: 190px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 26px;
    background-image: url(/background-login.png);
    background-size: cover;
}


.jumbotron h1 {
    text-align: center;
}

body a {
    transition: all 500ms;
}
body a:hover {
    color: #ED1B5D!important;
    opacity: 1!important;
}

body button.btn.btn-link{
    transition: all 500ms;
}

body button.btn.btn-link:hover {
    color: #ED1B5D!important;
}

body .btn-primary:hover {
    color: #fff;
    background-color: #ED1B5D;
    border-color: #ED1B5D;
}

.btn.btm-sm.btn-primary+button.btn.btm-sm.btn:hover {
    color: #ED1B5D!important;
}

.btn.btm-sm.btn-primary+button.btn.btm-sm.btn {
    background-color: transparent;
    color: black;
    text-decoration: underline;
    transition: all 500ms;
}

.table-info, .table-info>td, .table-info>th {
    border: none;
    padding: 0;
    background-color: transparent;
}

tr.table-info {
    background-color: transparent!important;
}

table.table.table-striped thead {
	border: 1px solid #80808075;
}

table.table.table-striped tbody {
	border: 1px solid #80808075;
}

table.table.table-striped table thead {
    border: none;
}

table.table.table-striped table tbody {
    border: none;
}

table.table.table-striped table thead {
    color: white;
    border: none;
    background-color: grey;
}

.table .table {
    margin-bottom: 0;
}

body .btn.focus, body .btn:focus {
    box-shadow: none;
}

a.linkex img {
    vertical-align: bottom;
    width: 16px;
    margin-right: 6px;
}

.inscrip img {
    max-width: 100%;
}

.inscrip>div+div {
    width: 35%;
}

.inscrip>div {
    width: 52%;
}

.inscrip {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
}

.inscrip+h4+form {
    margin-bottom: 40px;
}

.inscrip+h4+form input {
    max-width: 400px!important;
}

h3.textlv1 {
    margin-bottom: 20px;
}

h3.textlv1 span {
    white-space: pre-wrap;
}

.h3, h3 {
    font-size: 1.25rem;
  }
